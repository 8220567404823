/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { AlpineWebComponent, Attribute, Attributes, html } from "../alpineWebComponent";

const template = html`
  <span :class="rootClass">
    <slot></slot>
  </span>
`;

const attrs = {
  color: Attribute.String(),
  label: Attribute.String(),
};

type Attrs = Attributes<typeof attrs>;

interface State extends Attrs {
  rootClass: () => string;
}

export class Tag extends AlpineWebComponent<State, Attrs>("x-tag", attrs, template) {
  data() {
    return {
      rootClass: () =>
        `p-1 border inline-block rounded-md app-text-body-xs leading-3 border-${this.state.color} text-${this.state.color}`,
    };
  }
}

Tag.define();

export default Tag;
